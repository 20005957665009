import styles from './games.module.scss'
import refresh from '../../assets/icons/refresh.svg'
import exportIcon from '../../assets/icons/export.svg'
import { useContext, useState } from 'react'
// import GameImg from "../../assets/images/gameAsset.svg"
import LiveGameSessionsContent from '@/components/LiveGameSessionsContent'

import GameContext from '@/context/Games/GameContext'
import Spinner from '@/components/Spinner'




const LiveGameSessions = () => {

  const {liveSessions, liveSessionsLoading }  = useContext(GameContext)




  const [rotate, setRotate] = useState(false)

  const handleRefresh = () => {
    setRotate(true)
  }
 
  const handleClick = () => {
    setRotate(false)
  }
 

  return (
    <div className={styles["games__container__available"]}>
        <h2>Live Game Sessions</h2>
        <nav>

        <select name='menu' id='menu'>
            <optgroup>
            <option value='ALL'>Live Game Sessions ({liveSessions?.length})</option>
            <option value='PREVIOUS'>Previous Activities</option>
            <option value='NEXT'>Next Activities</option>
            </optgroup>
        </select>

        {/* refresh */}
        <div className={styles['refresh']} onClick={handleRefresh}>
            <div className={`anime ${rotate ? styles.rotate : ''}`}>
            <img src={refresh} alt='refresh' />
            </div>
            <p>Refresh</p>
        </div>

        {/* export */}
        <div className={styles['export']} onClick={handleClick}>
            <div>
            <img src={exportIcon} alt='export' />
            </div>
            <p>Export</p>
        </div>
        {/* </Form> */}
        </nav>

        <div className={styles["liveGame"]}>
            <>
              <div className={styles["liveGame__container"]}>
              
                {liveSessionsLoading? (
                  <Spinner />
                ) : !liveSessionsLoading && liveSessions?.length > 0 ? (
                  liveSessions?.map((liveGame, index) => (
                    <>
                      <LiveGameSessionsContent 
                        key={liveGame?.id} 
                        liveGame={liveGame} 
                        index={index}
                      />
                    </>
                  ))
                ) : (
                  <p>There are no games available at the moment</p>
                )}

              </div>
            </>
        </div>

    
    </div>
  )
}

export default LiveGameSessions
